import "./Filters.scss";

import React, { FC, useContext, useEffect } from "react";

import Button from "../Button";
import { t } from "i18next";
import NavContext from "../Layout/Nav/NavProvider";
import { FilterUrlConstants } from '../../constants/general';
import { useCustomHistory } from '../../hooks/useCustomHistory';

export interface FiltersProps {
  options: { [key: string]: string };
  opened: boolean;
  applyButtonHandler: () => void;
}

const Filters: FC<FiltersProps> = ({ opened, options, applyButtonHandler }) => {
  const { filters, setFilters, isMobile } = useContext(NavContext);
  const { replaceHistoryWithPathName } = useCustomHistory();

  const onClickOptionHandler = (key: string) => {
    if (key === "all" || !key) {
      setFilters([]);
      return;
    }

    const index = filters.findIndex((s) => s === key);
    if (index === -1) {
      setFilters([...filters, key]);
      return;
    }

    const filtersCopy = [...filters];
    filtersCopy.splice(index, 1);
    setFilters(filtersCopy);
  };

  const findCategoryKey = (param: string) => {
    const optionsList = Object.keys(options);
    const optionKey = optionsList.find(
      (key) => options[key].toLowerCase() === param.toLowerCase()
    );
    replaceHistoryWithPathName(isMobile());

    return optionKey;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlCategoryParam = params.get(FilterUrlConstants.Category);

    if (!urlCategoryParam) {
      return;
    }
    const filterKey = findCategoryKey(urlCategoryParam);

    onClickOptionHandler(filterKey);
  }, []);

  return (
    <div className={`filters ${opened ? "filters--opened" : ""}`}>
      <div className="filters__content">
        <div className="filters__button-apply-filter">
          <Button
            clickHandler={applyButtonHandler}
            uppercase={true}
            inverse={true}
          >
            {t("filters.apply")}
          </Button>
        </div>
        <ul className="filters__options">
          <li
            className={`filters__option cursor-click ${
              !filters.length ? "filters__option--active" : ""
            }`}
            onClick={() => onClickOptionHandler("all")}
          >
            <span className="filters__option-icon"></span>
            <span className="filters__option-link">{t("filters.all")}</span>
          </li>
          {Object.entries(options).map(([key, option]) => {
            return (
              <li
                key={key}
                className={`filters__option cursor-click ${
                  filters.includes(key) ? "filters__option--active" : ""
                }`}
                data-category={key}
                onClick={() => onClickOptionHandler(key)}
              >
                <span className="filters__option-icon"></span>
                <span className="filters__option-link">{option}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Filters;
