export const useCustomHistory = () => {
  const replaceHistoryWithPathName = (isMobile: boolean) => {
    if (isMobile) {
      return;
    }
    history.replaceState({}, document.title, "/" + location.pathname.slice(1));
  };

  const goBack = (defaultBackSlug?: string) => {
    const previousLocation = document.referrer;
    const isDomainUrl = previousLocation.includes(location.hostname);
 
    if (isDomainUrl) {
      location.href = previousLocation;
      return;
    }
    location.href = defaultBackSlug;
  };

  return { replaceHistoryWithPathName, goBack };
};
