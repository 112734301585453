import React, { FC, useContext, useEffect, useState } from "react";

import Slider from "../../components/Slider";
import { PressListItem } from "../../components";
import HomeSliderContact from "../../components/HomeSliderContact";
import NavContext from "../../components/Layout/Nav/NavProvider";
import "./PressSliders.scss";

export interface PressSliderProps {
  articles: any[];
  filterIndex: { [key: string]: string[] };
}

const PressSliders: FC<PressSliderProps> = ({ articles, filterIndex }) => {
  const { filters, isMobile } = useContext(NavContext);
  const [items, setItems] = useState<any[]>([]);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(null);

  function listItems(items) {
    return items?.map((item, i) => (
      <PressListItem key={item.slug.current} item={item} itemId={i} />
    ));
  }

  function slidesElements(items) {
    const elements: any[] = listItems(items);
    elements.push(<HomeSliderContact key="home-slider" />);

    return elements.map((el, index) => <span key={index}>{el}</span>);
  }

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  useEffect(() => {
    if (!filters.length) {
      setItems(articles);
      return;
    }

    let filteredItems = [];
    filters.forEach((filter) => {
      const matchedItems = filterIndex[filter] || [];
      filteredItems = [...filteredItems, ...matchedItems];
    });

    const filteredTalentsUniques = Array.from(new Set(filteredItems));
    setItems(filteredTalentsUniques);
  }, [articles, filters]);

  const pageContent = () => {
    return isMobileActive ? (
      <>
        <div className="press-sliders">{listItems(items)}</div>
        <HomeSliderContact key="home-slider" />
      </>
    ) : (
      <Slider>{slidesElements(items)}</Slider>
    );
  };

  return isMobileActive === null ? <></> : pageContent();
};
export default PressSliders;
