import "normalize.css";
import "./PressListTemplate.scss";

import { t } from "i18next";
import React, { FC, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import SEO from "../../components/SEO";
import { NavProvider } from "../../components/Layout/Nav/NavProvider";
import Layout from "../../components/Layout";

import Filters from "../../components/Filters";
import FilterButton from "../../components/FilterButton";
import { graphql } from "gatsby";
import PressSliders from "./PressSliders";
import { PageTemplateProps } from "../../constants/general";
import { getCurrentLanguage } from '../../i18n';

const PageTemplate: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const currentSlug = pageContext.slug;
  const language = getCurrentLanguage();
  const pageTitle = `${t("press.pageTitle")} Youplanet`;
  const description = `${t("press.pageDescription")} Youplanet`;
  const alternativeUrls = pageContext.alternativeUrls;

  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const getTags = (items: any[]) => {
    const tags: { [key: string]: string } = {};

    items.forEach((item) => {
      item.tags.forEach((tag) => {
        tags[tag.slug.current] = tag.name;
      });
    });
    return tags;
  };

  const getFilterIndex = (items: any[]) => {
    const index: { [key: string]: string[] } = {};

    items.forEach((item) => {
      item.tags.forEach((tag) => {
        if (!index[tag.slug.current]) {
          index[tag.slug.current] = [];
        }
        index[tag.slug.current].push(item);
      });
    });
    return index;
  };

  const dataList = (data.press.nodes as Array<any>).sort((a, b) => {
    const preDate = new Date(b.date);
    const nextDate = new Date(a.date);
    return preDate - nextDate;
  });
  
  const filterOptions = getTags(dataList);

  const onShowFilter = () => {
    setOpenFilters(true);
  };

  const onApplyFilter = () => {
    setOpenFilters(false);
  };

  return (
    <NavProvider>
      <Layout
        language={language}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={language}
          slug={currentSlug}
        />
        <SectionHeader h1={t("press.title")} inView={true} />
        <section className="press">
          <Filters
            options={filterOptions}
            opened={openFilters}
            applyButtonHandler={onApplyFilter}
          />
          <FilterButton onShowFilter={onShowFilter} />
          <PressSliders
            articles={dataList}
            filterIndex={getFilterIndex(dataList)}
          />
        </section>
      </Layout>
    </NavProvider>
  );
};

export default PageTemplate;

export const query = graphql`
  query PressListTemplateQuery($lang: String) {
    press: allSanityPress(
      filter: { i18n_lang: { in: [$lang, null] }, _id: { regex: "/^(?!drafts.).*/" } }
    ) {
      nodes {
        name
        date
        slug {
          current
        }
        content: _rawContent
        tags {
          name
          slug {
            current
          }
        }
        image {
          ...ImageFragment
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
