import "./FilterButton.scss";

import React, { FC, useContext } from "react";
import Button from "../Button";
import NavContext from "../Layout/Nav/NavProvider";
import { t } from "i18next";

export interface ButtonFilterProps {
  onShowFilter?: () => void;
}

const FilterButton: FC<ButtonFilterProps> = ({ onShowFilter }) => {
  const { footerInView } = useContext(NavContext);

  return (
    <div className={`filter-button ${footerInView ? "is-hidden" : ""}`}>
      <Button clickHandler={onShowFilter} uppercase={true}>
        {t('filters.button')}
      </Button>
    </div>
  );
};
export default FilterButton;
